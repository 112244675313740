import React, { useState, useEffect } from 'react';
import './App.css';

//set title
document.title = "Increase Bookings Now";

const App = () => {
  // State variables
  // hotels: stores the list of hotels fetched from the JSON file
  const [hotels, setHotels] = useState([]);
  // searchQuery: stores the search query entered by the user
  const [searchQuery, setSearchQuery] = useState('');
  // filteredHotels: stores the list of hotels filtered based on the search query
  const [filteredHotels, setFilteredHotels] = useState([]);
  // selectedHotel: stores the hotel selected by the user
  const [selectedHotel, setSelectedHotel] = useState(null);

  // Fetch data from the JSON file
  useEffect(() => {
    // Fetch data from the JSON file in the public directory
    fetch('/hotels.json')
      .then(response  => response.json())
      .then(data      => setHotels(data))
      .catch(error    => console.error('Error fetching data:', error));
  }, []);

  // Filter hotels based on the search query
  useEffect(() => {
    // Filter hotels based on the search query
    const results = hotels.filter(hotel =>
      hotel.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredHotels(results);
  }, [searchQuery, hotels]);

  // Event handlers
  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
    setFilteredHotels(hotels.filter(hotel =>
      hotel.name.toLowerCase().includes(e.target.value.toLowerCase())
    ));
  };

  // Handle click event on the hotel suggestion
  const handleHotelClick = (hotel) => {
    setSelectedHotel(hotel);
    setSearchQuery(hotel.name);
    setFilteredHotels([]);
  };

  // Handle blur event on the input field
  const handleBlur = () => {
    setTimeout(() => {
      setFilteredHotels([]);
    }, 100);
  };

  return (
    <div className="App">
    <header className="App-header">
      <img src="/logo192.png" className="App-logo" alt="logo" />
      <h1>IncrementalBooking.com</h1>
    </header>
      <div className="search-container">
        <input
          type="text"
          className="search-input"
          placeholder="Search hotels..."
          value={searchQuery}
          onChange={handleInputChange}
          onBlur={handleBlur}
        />        
        {searchQuery && filteredHotels.length > 0 && (
          <div className="suggestions">
            {filteredHotels.map(hotel => (
              <div
                key={hotel.id}
                className="suggestion-item"
                onMouseDown={() => handleHotelClick(hotel)}
              >
                {hotel.name}
              </div>
            ))}
          </div>
        )}
      </div>
      {selectedHotel && (
        <div>
          <h2>Selected Hotel</h2>
          <p>Name: {selectedHotel.name}</p>
          <p>Location: {selectedHotel.location}</p>
          <p>Price: ${selectedHotel.price}</p>
          <p>Rating: {selectedHotel.rating}</p>
        </div>
      )}
    </div>
  );
};

export default App;
